import React from "react";
import Modal from 'react-bootstrap/Modal';
import SlickImages from "./SlickImages";

import slider1 from '../img/home/slider1/slider1_imagen.jpg';
import slider2 from '../img/home/slider2/slider2_imagen.jpg';
import slider3 from '../img/home/slider3/slider3_imagen.jpg';
import logo from '../img/home/home_logo.jpg';
import welcome from '../img/home/welcome_bg.jpg';
import mission from '../img/mission/gray_bg.png';
import towing from '../img/services/towing.jpg';
import fuel from '../img/services/fuel.jpg';
import tradingFuels from '../img/services/b-trading-fuels.png';
import bExport from '../img/services/b-export.png';
import supplyFuels from '../img/services/b-supply-fuels.png';
import bSupply from '../img/services/b-supply.png';
import videolg from '../img/services/videos/video-lg.jpg';
import video01 from '../img/services/videos/video1.jpg';
import video01Mp4 from '../img/services/videos/video01.mp4';
import video02 from '../img/services/videos/video2.jpg';
import video02Mp4 from '../img/services/videos/video02.mp4';
import video03 from '../img/services/videos/video3.jpg';
import video03Mp4 from '../img/services/videos/video03.mp4';
import video04 from '../img/services/videos/video4.jpg';
import video04Mp4 from '../img/services/videos/video04.mp4';
import presencebg from '../img/presence/presence-bg.jpg';
import contactbg from '../img/contact/contact-bg.jpg';
import navbarLogo from '../img/home/navbar_logo.png';
import bgFooter from '../img/footer/bg_footer.png';
import btnRed from '../img/menu/btn_red.png';
import bricorLogo from '../img/home/bricor_logo.jpg';
import HLELogo from '../img/home/HLE_logo.jpg';


const menusES = [
    {
        id: 0,
        class: 'aboutus',
        text: 'CONÓCENOS'
    },  
    {
        id: 1,
        class: 'services',
        text: 'SERVICIOS'
    },  
    {
        id: 2,
        class: 'presence',
        text: 'PRESENCIA'
    },  
    {
        id: 3,
        class: 'contact',
        text: 'CONTACTO'
    }
];
const menusEN = [
    {
        id: 0,
        class: 'aboutus',
        text: 'ABOUT US'
    },  
    {
        id: 1,
        class: 'services',
        text: 'SERVICES'
    },  
    {
        id: 2,
        class: 'presence',
        text: 'PRESENCE'
    },  
    {
        id: 3,
        class: 'contact',
        text: 'CONTACT'
    }
];


class Content extends React.Component {
    
    constructor(props){
        super(props);
        
        this.state = {
            show: false,
            showVideo01: false,
            showVideo02: false,
            showVideo03: false,
            showVideo04: false,
            languaje: 'ES',
            activeES: 'active',
            activeEN: '',
            name: '',
            email: '',
            topic: '',
            message:'',
            emailStatus: '',
            sliders: [
                {
                    ES : [
                        {
                            id: 0,
                            title: '<div class="raleway-title-black">SERVICIOS PORTUARIOS</div><div class="raleway-title-medium">de Remolque y Salvataje<br/>en Puerto y Altamar</div>',
                            text: '"Pasión y Profesionalismo por el <br/> Sistema Portuario Mexicano".'
                        },
                        {
                            id: 1,
                            title: '<div class="raleway-title-medium">UBICADOS EN</div><div class="raleway-title-black">PUERTO CHIAPAS,<br/>CHIAPAS</div>',
                            text: 'Contribuyendo al desarrollo del Puerto y de la Región.'
                        },
                        {
                            id: 2,
                            title: '<div class="raleway-title-medium">REMOLQUE PARA EL</div><div class="raleway-title-black">ATRAQUE Y DESATRAQUE<br/>DE EMBARCACIONES</div>',
                            text: 'Eficiencia y Seguridad en todas las maniobras.'
                        }
                    ],
                    EN : [
                        {
                            id: 0,
                            title: '<div class="raleway-title-black">PORT SERVICES</div><div class="raleway-title-medium">Towing and Salvage in<br/>Port and High Seas</div>',
                            text: 'Passion and Professionalism <br/> for the Mexican Port System.'
                        },
                        {
                            id: 1,
                            title: '<div class="raleway-title-medium">LOCATED AT</div><div class="raleway-title-black">PUERTO CHIAPAS,<br/>CHIAPAS</div>',
                            text: 'Contributing to the development of the Port and the Region.'
                        },
                        {
                            id: 2,
                            title: '<div class="raleway-title-medium">TOWING SERVICES FOR</div><div class="raleway-title-black">DOCKING AND UNDOCKING<br/>OF VESSELS</div>',
                            text: 'Efciency and Safety in all maneuvers.'
                        }
                    ]
                }
                
            ],
            aboutusES: '"<strong>Somos una empresa naviera 100% mexicana dedicada al sector portuario. </strong>' +
            'Como prestadores de servicios contamos con personal altamente experimentado en el ramo, ' +
            'lo que ha permitido obtener el <strong>reconocimiento de calidad</strong> por parte de nuestros clientes, ' +
            'aliados comerciales y entidades gubernamentales regulatorias.”',
            aboutusEN: '"<strong>We are a 100% Mexican shipping company dedicated to the port sector.   </strong>' +
            'As service providers we have highly experienced personnel in the field, which has allowed us to obtain the ' +
            '<strong>recognition of quality </strong> by our customers, business partners and government regulatory entities”.',
            aboutus: [
                {
                    ES:[
                        {
                            id: 0,
                            title: 'VISIÓN',
                            text: 'Ser una firma que fomente el desarrollo del sector portuario mexicano '+
                            'a través de los servicios que ofrecemos de manera efectiva; mejorando '+
                            'la calidad de vida de la población en nuestra área de influencia y '+
                            'respetando el medio ambiente.'
                        },
                        {
                            id: 1,
                            title: 'MISIÓN',
                            text: 'Ser un vehículo para el desarrollo y crecimiento del sector portuario en '+
                            'todos los puertos donde estemos presentes, por la importancia que le damos '+
                            'a la sustentabilidad, retos y gestión de nuestros servicios a partir de la '+
                            'capacitación permanente y constante, al compromiso con nuestra identidad '+
                            'empresarial, código de honor, excelencia y la vanguardia en nuestros procesos '+
                            'y marcos normativos aplicables.'+
                            '<br/> <br/>'+
                            'Bricor Servicios Portuarios Mexicanos, como parte del grupo empresarial High '+
                            'Level Energy, ha identificado las  diversas áreas de oportunidad en las cadenas '+
                            'logísticas del comercio nacional e internacional en el sureste del País  y se '+
                            'ha tomado la decisión de explotar el potencial de la zona del sureste de México '+
                            'así como la conectividad del Istmo de Tehuantepec a través del puerto marítimo '+
                            'de la región, toda vez que los puertos mexicanos  se alzan como los eslabones '+
                            'clave en la conectividad de  nuestro País con el resto del mundo, posibilitando '+
                            'el comercio internacional."'
                        }
                    ],
                    EN:[
                        {
                            id:0,
                            title: 'VISION',
                            text: 'To be a company that promotes the development of the Mexican port sector through '+
                            'the services we offer always in an effective manner; improving the quality of life of the '+
                            'population in our area of influence and respecting the environment.'
                        },
                        {
                            id: 1,
                            title: 'MISION',
                            text: 'To be a vehicle for the development and growth of the port sector in all '+
                            'ports where we are present, because of the importance we give to sustainability, '+
                            'remains and management of our services from the permanent and continuous training, '+
                            'the commitment to our corporate identity, code of honor, excellence and the forefront '+
                            'in our processes and applicable regulatory frameworks.'+
                            '<br/> <br/>'+
                            'Bricor Servicios Portuarios Mexicanos, as part of the High Level Energy business group, '+
                            'has identified the various areas of opportunity in the logistics chains of national and '+
                            'international trade affairs in the southeast of the country and has decided to exploit '+
                            'the potential of the area as well as the connectivity of the Isthmus of Tehuantepec through '+
                            'the seaport of the region, since Mexican ports stand as key links in the connectivity of our '+
                            'country with the rest of the world, enabling international trade.'
                        }
                    ]
                }
            ],
            services:[
                {
                    ES: [
                        {
                            id: 0,
                            title: 'SERVICIO DE REMOLQUE',
                            text: '"Con la intención de reforzar la capacidad y el desarrollo de los puertos mexicanos, '+
                            'nuestro grupo ofrece el servicio de remolque en Puerto Chiapas con el Remolcador Bricor '+
                            'SPM basados en la confianza y certeza que genera el Gobierno Federal a través del '+
                            'impulso al sistema portuario nacional como polos de fomento en el desarrollo regional, '+
                            'nacional e internacional.'+
                            '<br/><br/>'+
                            'Con un contrato de prestación de servicios portuarios celebrado con la Administración '+
                            'del Sistema Portuario Nacional Puerto Chiapas, la embarcación tipo remolcador "BRICOR SPM" '+
                            'está debidamente equipada para prestar servicios contando con un equipo de profesionales '+
                            'con una amplia experiencia en el sector de prestación de servicios portuarios dotando de '+
                            'certeza, seguridad y profesionalismo las maniobras de atraque, desatraque, enmienda y '+
                            'salvamento en el recinto portuario, coadyuvando con el crecimiento del Puerto."'+
                            '<br/><br/>'+
                            'Servicios:'+
                            '<br/>'+
                            '1. Servicios de Remolque.'+
                            '<br/>'+
                            '2. Servicios de Salvataje en Puerto y Altamar.',
                            button: 'Consulta las tarifas actualizadas'
                        },
                        {
                            id: 1,
                            title: 'VENTA DE COMBUSTIBLES',
                            text: 'Con miras a consolidar nuestra posición en Chiapas, el próximo objetivo del grupo radica '+
                            'en la construcción y operación de un Sistema de Ductos Bidireccionales, ya que hemos '+
                            'identificado que el recinto portuario se encuentra a escasos 2 Km de la Terminal de '+
                            'Almacenamiento y Despacho (TAD) de Pemex Logística en Tapachula, Chiapas.'
                        }
                    ],
                    EN: [
                        {
                            id: 0,
                            title: 'TOWING SERVICES',
                            text: '"With the intention of strengthening the capacity and development of Mexican ports, '+
                            'our group offers towing services in Puerto Chiapas with the tugboat Bricor SPM, based on '+
                            'the confidence and certainty generated by the Federal Government through the promotion of '+
                            'the national port system as a pole of regional, national and international development.'+
                            '<br/><br/>'+
                            'With a contract for the provision of port services entered into with the Administration of '+
                            'the National Port System Puerto Chiapas, the tugboat type vessel "BRICOR SPM" is properly '+
                            'equipped to provide services with a team of professionals with extensive experience in the '+
                            'sector of providing port services providing certainty, security and professionalism in the '+
                            'maneuvers of docking, undocking, amendment and salvage in the port area, contributing to '+
                            'the growth of the Port."'+
                            '<br/><br/>'+
                            'Services:'+
                            '<br/>'+
                            '1. Towing Services.'+
                            '<br/>'+
                            '2. Salvage Services in Port and International Seas.',
                            button:'Check the updated fees'
                        },
                        {
                            id: 1,
                            title: 'FUEL TRADING',
                            text: '"With a view to consolidating our position in Chiapas, the group’s next objective lies in '+
                            'the construction and operation of a Bidirectional Pipeline System, as we have identified that the port '+
                            'precinct is located just 0.62 miles away from the Storage and Dispatch terminal (TAD) of Pemex in Tapachula, '+
                            'Chiapas."'
                        }
                    ]
                }
            ],
            bFocus: [
                {
                    ES: [
                        {
                            id: 0,
                            text: 'ENFOQUES DEL NEGOCIO'
                        },
                        {
                            id: 1,
                            text: '<strong>Comercialización de combustibles</strong> en muelles '+
                            'para el <strong>autoconsumo<br/> de las embarcaciones</strong><br/> usuarias '+
                            'de Puerto Chiapas.'
                        },
                        {
                            id: 2,
                            text: '<strong>Exportación de productos petrolíferos</strong> <br/> por vía marítima y ducto.'
                        },
                        {
                            id: 3,
                            text: '<strong>Abastecimiento de combustibles a la Terminal de Almacenamiento у '+
                            'Despacho de Pemex</strong> Logística en Tapachula por vía marítima y ducto.'
                        },
                        {
                            id: 4,
                            text: '<strong>Suministro y comercialización de combustibles '+
                            'a embarcaciones</strong> off shore (costa afuera).'
                        },
                        {
                            id: 5,
                            text: '<strong>Bricor</strong> adquirió un predio colindante con Puerto Chiapas que '+
                            'garantizó la adjudicación directa del contrato de cesión parcial '+
                            'de derechos correspondiente, y que además permite la instalación '+
                            'de tanques para inventario y el proceso de blending (para '+
                            'garantizar la calidad y especificación requerida por clientes), '+
                            'asimismo salvaguarda el sistema de ductos y contempla llenaderas '+
                            'de autotanques para exportación vía terrestre y comercialización '+
                            'en la zona mediante la empresa comercializadora del grupo High Level Energy.'
                        }
                    ],
                    EN: [
                        {
                            id: 0,
                            text: 'BUSINESS FOCUS'
                        },
                        {
                            id: 1,
                            text: '<strong>Trading fuels</strong> in docks for the<br/>'+
                            '<strong>self-consumption of the vessels</strong><br/> that '+
                            'use Puerto Chiapas.'
                        },
                        {
                            id: 2,
                            text: '<strong>Export of petroleum products</strong> <br/> by sea and pipeline.'
                        },
                        {
                            id: 3,
                            text: '<strong>Supply of fuels to the <br/> Pemex Storage and <br/> '+
                            'Dispatch Terminal </strong> <br/> by sea and pipeline.'
                        },
                        {
                            id: 4,
                            text: '<strong>Supply and trade <br/> '+
                            'to offshore vessels</strong>'
                        },
                        {
                            id: 5,
                            text: '<strong>Bricor</strong> acquired a property adjacent to Puerto Chiapas that guaranteed '+
                            'the direct award of the corresponding partial assignment of rights and obligations contract, '+
                            'which also allows the installation of tanks for inventory and blending process (to guarantee '+
                            'the quality and specifications required by clients), and it also safeguards the pipeline system '+
                            'and contemplates filling tank trucks for the export of fuels by land and trading in the area '+ 
                            'through the marketer company of the High Level Energy group.'
                        }
                    ]
                }
            ],
            presence: [
                {
                    ES: [
                        {
                            id: 0,
                            title: 'PRESENCIA Y CONTACTO'
                        }
                    ],
                    EN: [
                        {
                            id: 0,
                            title: 'PRESENCE AND CONTACT'
                        }
                    ]
                }
            ],
            contact: [
                {
                    ES: [
                        {
                            id: 0,
                            title: 'CONTÁCTANOS',
                            tname: 'Nombre',
                            temail: 'Correo electrónico',
                            ttopic: 'Asunto',
                            tmessage: 'Mensaje',
                            button: 'Enviar'
                        }
                    ],
                    EN: [
                        {
                            id: 0,
                            title: 'CONTACT US',
                            tname: 'Name',
                            temail: 'Email',
                            ttopic: 'Topic',
                            tmessage: 'Message',
                            button: 'Send'
                        }
                    ]
                }
            ]
        }
    }
    
    setLanguaje=(lang)=>{
        if (lang === 'ES'){
            this.setState({activeES: 'active', activeEN: ''});
        }else {
            this.setState({activeES: '', activeEN: 'active'});
        }
        this.setState({languaje: lang});
    }

    handleChange = input => e => {
        this.setState({
            [input]: e.target.value
        });
    }

    submitForm = (e) => {
        const { 
            name, email, topic, message, languaje
        } = this.state;
        var xhr = new XMLHttpRequest();
        xhr.addEventListener('load', () => {
           this.setState({
            emailStatus: xhr.responseText,
            show: !this.show
           })
           if ((xhr.responseText === "Message has been sent") || (xhr.responseText  === "El mensaje ha sido enviado")) {
            this.setState({
                name: '',
                email: '',
                topic: '',
                message: ''
            })
           }
        });
        xhr.open('GET', 'https://bricorspm.com/sendmail/index.php?sendto=' + email +
        '&name=' + name +
        '&topic=' + topic +
        '&message=' + message +
        '&lang=' + languaje);
        xhr.onload = () => {
        };
        xhr.send();
        console.log(xhr);
        e.preventDefault();
    }

    setShow = () =>{
        this.setState({show: !this.state.show})
    }
    setShowVideo01 = () =>{
        this.setState({showVideo01: !this.state.showVideo01})
    }
    setShowVideo02 = () =>{
        this.setState({showVideo02: !this.state.showVideo02})
    }
    setShowVideo03 = () =>{
        this.setState({showVideo03: !this.state.showVideo03})
    }
    setShowVideo04 = () =>{
        this.setState({showVideo04: !this.state.showVideo04})
    }
    mailtoAdmin = () => {
        window.open('mailto:admon@bricorspm.com?subject=Administración');
    }
    mailtoContact = () => {
        window.open('mailto:contacto@bricorspm.com?subject=Contacto');
    }
    btnFacebook = () => {
        
    }
    btnX = () => {
        
    }
    setNavExpanded(expanded) {
        this.setState({ navExpanded: expanded });
    }
    
    closeNav() {
        this.setState({ navExpanded: false });
    }

    render() {
        const { 
            languaje, activeES, activeEN, sliders, aboutusES, aboutusEN,
            aboutus, services, bFocus, presence, contact, name, email, 
            topic, message, show, emailStatus, showVideo01, showVideo02,
            showVideo03, showVideo04
        } = this.state;
        const arrayDataMenuES = menusES.map(menuES => 
            <a key={menuES.id} className={`nav-link`} href={`#${menuES.class}`}>{menuES.text}</a>
        )
        const arrayDataMenuEN = menusEN.map(menuEN => 
            <a key={menuEN.id} className={`nav-link`} href={`#${menuEN.class}`}>{menuEN.text}</a>
        )

        return (
            <div data-bs-spy="scroll" data-bs-target=".navbar" data-offset="50" >
                <nav className='navbar fixed-top navbar-expand-lg bg-menu'>
                    <div className='container'>
                        <a className='navbar-brand me-auto' href='#home'>
                            <img src={navbarLogo}  alt='Logo' height="69"/>
                        </a>
                        <div className="navbar-lang">
                        <ul className='nav nav-pills'>
                                <img src={btnRed} className='d-block logo-world ' alt='...'></img>
                                <button className={`nav-link ${activeEN}`}  type='button' role='tab' aria-controls='pills-en' aria-selected='false' onClick={() => this.setLanguaje('EN')}>EN</button>
                                <button className={`nav-link ${activeES}`}  type='button' role='tab' aria-controls='pills-es' aria-selected='false' onClick={() => this.setLanguaje('ES')}>ES</button>
                            </ul>
                        </div>
                        <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false' aria-label='Toggle navigation'>
                            <span className='navbar-toggler-icon'>
                            
                            </span>
                        </button>
                        <div className='collapse justify-content-end navbar-collapse' id='navbarSupportedContent'>
                            <ul className='nav navbar-nav navbar-right' id='pills-tab' role='tablist'>
                                {languaje === 'ES' ? (arrayDataMenuES):(arrayDataMenuEN)} 
                                <br/>
                            </ul>
                            <ul className='nav nav-pills navbar-lang-max'>
                                <img src={btnRed} className='d-block logo-world ' alt='...'></img>
                                <button className={`nav-link ${activeEN}`}  type='button' role='tab' aria-controls='pills-en' aria-selected='false' onClick={() => this.setLanguaje('EN')}>EN</button>
                                <button className={`nav-link ${activeES}`}  type='button' role='tab' aria-controls='pills-es' aria-selected='false' onClick={() => this.setLanguaje('ES')}>ES</button>
                            </ul>
                        </div>
                        
                    </div>
                </nav>
                <div>
                    <section className='section-one' id='home'>
                    </section>
                    
                    <section>
                        <div id='carouselBricor' className='carousel slide'>
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselBricor" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselBricor" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselBricor" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div>
                            <div className='carousel-inner'>
                                <div className='carousel-item active'>
                                <img src={slider1} className='d-block w-100' alt='...'/>
                                    <div className="carousel-caption d-md-block">
                                        <div dangerouslySetInnerHTML={{__html: languaje === 'ES' ? (sliders[0].ES[0].title):(sliders[0].EN[0].title)}}/> 
                                        <br/>   
                                        <div className='open-sans-text' dangerouslySetInnerHTML={{__html: languaje === 'ES' ? (sliders[0].ES[0].text):(sliders[0].EN[0].text)}}>
                                        </div>
                                    </div>
                                </div>
                                <div className='carousel-item'>
                                <img src={slider2} className='d-block w-100' alt='...'/>
                                    <div className="carousel-caption d-md-block">
                                        <div dangerouslySetInnerHTML={{__html: languaje === 'ES' ? (sliders[0].ES[1].title):(sliders[0].EN[1].title)}}/> 
                                        <br/>
                                        <div className='open-sans-text'>
                                        {languaje === 'ES' ? (sliders[0].ES[1].text):(sliders[0].EN[1].text)}
                                        </div>
                                    </div>
                                </div>
                                <div className='carousel-item'>
                                <img src={slider3} className='d-block w-100' alt='...'/>
                                    <div className="carousel-caption d-md-block">
                                        <div dangerouslySetInnerHTML={{__html: languaje === 'ES' ? (sliders[0].ES[2].title):(sliders[0].EN[2].title)}}/> 
                                        <br/>
                                        <div className='open-sans-text'>
                                            {languaje === 'ES' ? (sliders[0].ES[2].text):(sliders[0].EN[2].text)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className='carousel-control-prev' type='button' data-bs-target='#carouselBricor' data-bs-slide='prev'>
                                <span className='carousel-control-prev-icon' aria-hidden='true'></span>
                                <span className='visually-hidden'>Previous</span>
                            </button>
                            <button className='carousel-control-next' type='button' data-bs-target='#carouselBricor' data-bs-slide='next'>
                                <span className='carousel-control-next-icon' aria-hidden='true'></span>
                                <span className='visually-hidden'>Next</span>
                            </button>
                        </div>
                    </section>
                    <section className='section'>
                        <div>
                        <img src={logo} className='d-block w-100' alt='...'/>
                        </div>
                    </section>
                    <div id='aboutus'>
                        <section className='section' > 
                            <div className='image-inner'>
                                <div className='image-item'>
                                    <img src={welcome} className='d-block w-100' alt='...'/>
                                    <div className='image-caption d-md-block'>
                                        <div className='open-sans-medium'>
                                            <p dangerouslySetInnerHTML={{__html: languaje === 'ES' ? aboutusES:aboutusEN}}>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    
                        <section className='section'>
                            <div className='image-inner'>
                                <div className='image-item'>
                                <img src={mission} className='d-block w-100' alt='...'/>
                                    <div className='image-caption-fluid d-md-block'>
                                        <div className='container-fluid'>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <p className='raleway-semibold-red'>{languaje === 'ES' ? aboutus[0].ES[0].title:aboutus[0].EN[0].title}</p>
                                                    <p className='open-sans-regular' dangerouslySetInnerHTML={{__html: languaje === 'ES' ? aboutus[0].ES[0].text:aboutus[0].EN[0].text}}>
                                                    </p>
                                                </div>
                                                <div className='col-1 col-width-center'>
                                                    <div className='gradient-line'></div>
                                                    
                                                </div>
                                                <div className='col'>
                                                <p className='raleway-semibold-blue'>{languaje === 'ES' ? aboutus[0].ES[1].title:aboutus[0].EN[1].title}</p>
                                                <p className='open-sans-regular' dangerouslySetInnerHTML={{__html: languaje === 'ES' ? aboutus[0].ES[1].text:aboutus[0].EN[1].text}}>
                                                </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                            
                            </div>
                        </section>
                    </div>
                    <div id='services'>
                        <section className='section' >
                            <div className='image-inner'>
                                <div className='image-item'>
                                    <img src={towing} className='d-block w-100' alt='...'/>
                                    <div className='image-caption-left d-md-block'>
                                        <div className='container-fluid'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='raleway-semibold-blue'><p className='size'>{languaje === 'ES' ? services[0].ES[0].title:services[0].EN[0].title}</p></div>
                                                    <p className='open-sans-regular' dangerouslySetInnerHTML={{__html: languaje === 'ES' ? services[0].ES[0].text:services[0].EN[0].text}}>
                                                    </p>
                                                    <a href="https://bricorspm.com/content/tarifas.pdf" target="_blank" rel="noreferrer" type='button' className='btn btn-outline-primary btn-outline-custom'>{languaje === 'ES' ? services[0].ES[0].button:services[0].EN[0].button}</a>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='section'>
                            <div className='container-fluid'>
                                <div className='section-caption'>
                                    <div className='row'>
                                        <div className='col-2'>
                                            <img src={bricorLogo} className='d-block w-100' alt='...'/>
                                        </div>
                                        <div className='col-2'>
                                            <img src={HLELogo} className='d-block w-100' alt='...'/>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className='raleway-semibold-blue'><p className='size'>{languaje === 'ES' ? services[0].ES[1].title:services[0].EN[1].title}</p></div>
                                            <p className='open-sans-regular'>
                                                {languaje === 'ES' ? services[0].ES[1].text:services[0].EN[1].text}
                                                <br/>
                                            </p>
                                        </div>
                                    </div> 
                                    <div className='row'>
                                        <div className='col'>
                                            <div className='open-sans-regular' dangerouslySetInnerHTML={{__html: languaje === 'ES' ? bFocus[0].ES[5].text:bFocus[0].EN[5].text}}> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className='open-sans-extrabold-red separator'>{languaje === 'ES' ? bFocus[0].ES[0].text:bFocus[0].EN[0].text}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='section'>
                            <div className='image-inner'>
                                <div className='image-item'>
                                    <img src={fuel} className='d-block w-100' alt='...'/>
                                    <div className='image-caption-right'>
                                        <div className='row'>
                                            <div className='col-width-card'>
                                                <div className='card'>
                                                    <img src={tradingFuels} className='d-block card-image' alt='...'/>
                                                    <div className='center-items'> 
                                                    <p className='card-text' dangerouslySetInnerHTML={{__html: languaje === 'ES' ? bFocus[0].ES[1].text:bFocus[0].EN[1].text}}>
                                                    
                                                    </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-width-card-center'></div>
                                            <div className='col-width-card'>
                                                <div className='card'>
                                                    <img src={bExport} className='d-block card-image' alt='...'/>
                                                    <div className='center-items'>
                                                        <p className='card-text' dangerouslySetInnerHTML={{__html: languaje === 'ES' ? bFocus[0].ES[2].text:bFocus[0].EN[2].text}}>
                                                
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-width-card'>
                                                <div className='card'>
                                                    <img src={supplyFuels} className='d-block card-image' alt='...'/>
                                                    <div className='center-items'>
                                                        <p className='card-text' dangerouslySetInnerHTML={{__html: languaje === 'ES' ? bFocus[0].ES[3].text:bFocus[0].EN[3].text}}>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-width-card-center'></div>
                                            <div className='col-width-card'>
                                                <div className='card'>
                                                    <img src={bSupply} className='d-block card-image' alt='...'/>
                                                    <div className='center-items'>
                                                        <p className='card-text' dangerouslySetInnerHTML={{__html: languaje === 'ES' ? bFocus[0].ES[4].text:bFocus[0].EN[4].text}}>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <br/>
                        <section>

                        </section>  
                        <section>
                            <SlickImages/>
                        </section> 
                        <br/>
                        <section>
                        </section>
                        <section className='section'>
                            <div className='image-inner'>
                                <div className='image-item'>
                                    <img src={videolg} className='d-block w-100' alt='...'/>
                                    <div className='image-caption-center d-md-block'>
                                        <div className='raleway-semibold-red'>
                                                <p className='size'></p>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </section>
                        <section className='section'>
                            <div className='container'>
                                <div className='row row-margin'>
                                    <div className='col-6 col-margin'>
                                        <div className="video">
                                            <img src={video01} className='d-block w-100' alt='...'/>
                                            <button type="button" className="btn-play" onClick={() => this.setShowVideo01()}></button>
                                        </div>
                                    </div>
                                    <Modal
                                        show={showVideo01}
                                        onHide={() => this.setShowVideo01()}
                                        dialogClassName="modal-dialog modal-fullscreen"
                                        aria-labelledby="example-custom-modal-styling-title"
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="center-items">
                                        <video src={video01Mp4} autoPlay width="100%" height="100%" controls></video>
                                        </Modal.Body>
                                    </Modal>
                                    <div className='col-6 col-margin'>
                                        <div className="video">
                                            <img src={video02} className='d-block w-100' alt='...'/>
                                            <button type="button" className="btn-play" onClick={() => this.setShowVideo02()}></button>
                                        </div>
                                    </div>
                                    <Modal
                                        show={showVideo02}
                                        onHide={() => this.setShowVideo02()}
                                        dialogClassName="modal-dialog modal-fullscreen"
                                        aria-labelledby="example-custom-modal-styling-title"
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="center-items">
                                        <video src={video02Mp4} autoPlay width="100%" height="100%" controls></video>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                                <div className='row row-margin'>
                                    <div className='col-6 col-margin'>
                                        <div className="video">
                                            <img src={video03} className='d-block w-100' alt='...'/>
                                            <button type="button" className="btn-play" onClick={() => this.setShowVideo03()}></button>
                                        </div>
                                    </div>
                                    <Modal
                                        show={showVideo03}
                                        onHide={() => this.setShowVideo03()}
                                        dialogClassName="modal-dialog modal-fullscreen"
                                        aria-labelledby="example-custom-modal-styling-title"
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="center-items">
                                        <video src={video03Mp4} autoPlay width="100%" height="100%" controls></video>
                                        </Modal.Body>
                                    </Modal>
                                    <div className='col-6 col-margin'>
                                        <div className="video">
                                            <img src={video04} className='d-block w-100' alt='...'/>
                                            <button type="button" className="btn-play" onClick={() => this.setShowVideo04()}></button>
                                        </div>
                                    </div>
                                    <Modal
                                        show={showVideo04}
                                        onHide={() => this.setShowVideo04()}
                                        dialogClassName="modal-dialog modal-fullscreen"
                                        aria-labelledby="example-custom-modal-styling-title"
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="center-items">
                                        <video src={video04Mp4} autoPlay width="100%" height="100%" controls></video>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div id='presence'>
                        <section className='section' >
                            <div className='image-inner'>
                                <div className='image-item'>
                                    <img src={presencebg} className='d-block w-100' alt='...'/>
                                    <div className='image-caption-top d-md-block'>
                                        <div className='raleway-semibold-red'>
                                            <p className='size'>{languaje === 'ES' ? presence[0].ES[0].title:presence[0].EN[0].title}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Modal
                        show={show}
                        onHide={() => this.setShow()}
                        dialogClassName="modal-dialog modal-dialog-centered"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Body className="center-items">
                        <p >
                            {emailStatus}
                        </p>
                     <div>
                        <button type='button' className='btn btn-outline-primary btn-outline-custom2' onClick={()=>this.setShow()}>Ok</button>
                     </div>
                        </Modal.Body>
                    </Modal>
                   
                    <div id='contact'>
                        <section className='section' >
                            <div className='image-inner' >
                                <div className='image-item'>
                                    <img src={contactbg} className='d-block w-100' alt='...'/>
                                    <div className='image-caption-center d-md-block'>
                                        <div className='container'>
                                            <div className='row row-content-center'>
                                                <div className='col-6'>
                                                    <div className='raleway-semibold-red'>
                                                        <p className='size'>{languaje === 'ES' ? contact[0].ES[0].title:contact[0].EN[0].title}</p>
                                                        <form onSubmit={this.submitForm}>
                                                            <div className="mb-3">
                                                                <input type='text' className='form-control open-sans-italic' id='inputName' placeholder={languaje === 'ES' ? contact[0].ES[0].tname:contact[0].EN[0].tname} value={name} onChange={this.handleChange('name')}/>
                                                            </div>
                                                            <div className="mb-3">
                                                                <input type='email' className='form-control open-sans-italic' id='inputEmail' placeholder={languaje === 'ES' ? contact[0].ES[0].temail:contact[0].EN[0].temail} value={email} onChange={this.handleChange('email')}/>
                                                            </div>
                                                            <div className="mb-3">
                                                                <input type='text' className='form-control open-sans-italic' id='inputTopic' placeholder={languaje === 'ES' ? contact[0].ES[0].ttopic:contact[0].EN[0].ttopic} value={topic} onChange={this.handleChange('topic')}/>
                                                            </div>
                                                            <div className="mb-3">
                                                                <textarea type='text' className='form-control open-sans-italic' id='inputMessage' placeholder={languaje === 'ES' ? contact[0].ES[0].tmessage:contact[0].EN[0].tmessage} value={message} onChange={this.handleChange('message')}/>
                                                            </div>
                                                            <div className="mb-3">
                                                                <div className="btn-contact-group">
                                                                    <button type='button' onClick={() => this.mailtoAdmin()} className="btn-admin"></button>
                                                                    <button type='button' onClick={() => this.mailtoContact()} className="btn-mail"></button>
                                                                    <button type='submit' className='btn btn-outline-primary btn-outline-custom2'>{languaje === 'ES' ? contact[0].ES[0].button:contact[0].EN[0].button}</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <section className="footer">
                        <div className='image-inner'>
                            <div className='image-item'>
                                <img src={bgFooter} className='d-block w-100' alt='...'/>
                                <div className='image-caption-left d-md-block'>
                                    <div className='container-fluid'>
                                        <div className='row '>
                                            <div className='col-4 open-sans-regular-footer footer-left-center'>
                                            Copyright © 2020 by BricorSPM. All rights reserved. 
                                            </div>
                                            <div className='col-4 open-sans-regular-footer footer-center'>
                                            <a href="https://bricorspm.com/content/Aviso_de_Privacidad_BSPM.pdf" target="_blank" rel="noreferrer" className="alink" >Aviso de Privacidad / <em>Privacy Statement.</em></a>
                                            </div>
                                            <div className='col-4 open-sans-bold-footer footer-right-center'>
                                           {/* Follow Us &nbsp;&nbsp;&nbsp;
                                           <button type='button' onClick={() => this.btnFacebook()} className="btn-facebook"/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <button type='button' onClick={() => this.btnX()} className="btn-x"/>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default Content;