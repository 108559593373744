import React from "react";
import Slider from "react-slick";

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div>
        <button onClick={onClick} className='carousel-control-next' type='button' data-bs-target='#carouselBricor' data-bs-slide='next'>
            <span className='carousel-control-next-icon' aria-hidden='true'></span>
            <span className='visually-hidden'>Next</span>
        </button>
      </div>
    );
  }
function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div>
        <button onClick={onClick} className='carousel-control-prev' type='button' data-bs-target='#carouselBricor' data-bs-slide='prev'>
            <span className='carousel-control-prev-icon' aria-hidden='true'></span>
            <span className='visually-hidden'>Previous</span>
        </button>
      </div>
    );
  }

function SlickImages() {
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "25%",
        slidesToShow: 1,
        speed: 500,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
      };
      return (
        <div className="slider-container">
          <Slider {...settings}>
            <div>
                <img src="https://bricorspm.com/content/images/slider_slick/image01.jpg" className='img-property' alt='...'/>
            </div>
            <div>
                <img src="https://bricorspm.com/content/images/slider_slick/image02.jpg" className='img-property' alt='...'/>
            </div>
            <div>
                <img src="https://bricorspm.com/content/images/slider_slick/image03.jpg" className='img-property' alt='...'/>
            </div>
            
          </Slider>
        </div>
      );
    }

export default SlickImages;